// TODO: Remove any functions that end up not getting used. (These were all copied over from the
// Pillars Fitness Examiner app and I'm not sure which are necessary for ASHCare.)

// Returns a new string with the first letter of each word capitalized and all other
// letters lower case. (Good for fixing casing of member names)
export const toTitleCase = str =>
	str
		.toLowerCase()
		.split(' ')
		.map(word => word.charAt(0).toUpperCase() + word.slice(1))
		.join(' ');

// Returns a new date in the form of MM/DD/YYYY
export const formatDate = dateString =>
	dateString ? new Date(dateString).toLocaleDateString() : '';

// Returns either a formatted address or an empty string if any of the essential fields are blank.
export const formatAddress = (address1, address2, city, state, zipCode) => {
	const missingAddressField = !(address1 && city && state && zipCode); // All fields are essential except address2.
	const formattedAddress2 = address2 ? ` ${address2}` : '';

	return missingAddressField
		? ''
		: `${address1}${formattedAddress2}, ${city}, ${state} ${zipCode}`;
};

// Convert decimal number into currency format.
export const formatCurrency = decimalNumber => {
	const formatter = new Intl.NumberFormat('en-US', {
		style: 'currency',
		currency: 'USD',
	});

	return formatter.format(decimalNumber);
};

export const getClientCodeFromPath = pathname => {
	const clientCode = pathname.split('/')[2] || '';
	return clientCode.toLowerCase();
};

export const getProgramFromPath = pathname => pathname.split('/')[1] || '';
