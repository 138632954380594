import { useQuery } from 'react-query';
import { fetchSsoRoute } from '../apiCalls';
import { QueryKey } from './QueryKeys';
import { useNavigate } from 'react-router-dom';

// Query for the single sign-on route for the external site based off of the feature passed in and then open
// that page in a new window. If an error is returned by the server, navigate to the error page.
const useSsoRedirect = feature => {
	const { refetch: getSsoRoute } = useQuery(
		[QueryKey.GET_SSO_REDIRECT, feature],
		fetchSsoRoute,
		{
			// Prevent this query from automatically running because we only want to get the data
			// if the user navigates to the external site.
			enabled: false,
		}
	);
	const navigate = useNavigate();

	const ssoRedirect = () => {
		getSsoRoute()
			.then(({ data }) => {
				// If the server returns a redirect URL, navigate to it. If it returns any error, navigate
				// to the error page.
				if (data?.redirectUri) {
					window.open(data.redirectUri);
				} else navigate('/500');
			})
			.catch(() => {
				navigate('/500');
			});
	};

	return ssoRedirect;
};

export default useSsoRedirect;
