import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Stack from '@mui/material/Stack';
import { string, bool, arrayOf, element } from 'prop-types';
import { useNavigate } from 'react-router-dom';
import LaunchIcon from '@mui/icons-material/Launch';
import { useState } from 'react';
import KeyboardArrowDownOutlinedIcon from '@mui/icons-material/KeyboardArrowDownOutlined';
import KeyboardArrowRightOutlinedIcon from '@mui/icons-material/KeyboardArrowRightOutlined';
import Collapse from '@mui/material/Collapse';
import { SubLinkType } from '../../../scripts/customPropTypes';
import useSsoRedirect from '../../../data/queryHooks/useSsoRedirect';

export const NavListItemButton = ({
	isExternal,
	isReact,
	href,
	ssoFeature,
	children,
	subLinks,
	icon,
	...props
}) => {
	const navigate = useNavigate();
	const [open, setOpen] = useState(false);
	const ssoRedirect = useSsoRedirect(ssoFeature);

	const handleClick = () => {
		setOpen(!open);
	};

	const subLinkIcon = open ? (
		<KeyboardArrowDownOutlinedIcon />
	) : (
		<KeyboardArrowRightOutlinedIcon />
	);

	const getOnClick = () => {
		let onClick;

		if (subLinks?.length > 0) {
			onClick = handleClick;
		} else if (isReact) {
			onClick = () => navigate(href);
		} else if (ssoFeature) {
			onClick = ssoRedirect;
		} else onClick = null;

		return onClick;
	};

	return (
		<>
			<ListItem disablePadding>
				<ListItemButton
					onClick={getOnClick()}
					href={!isReact && !ssoFeature ? href : ''}
					target={isExternal ? '_blank' : ''}
					{...props}
				>
					<Stack alignItems="center" px={3} py={1} direction="row">
						{icon && (
							<ListItemIcon>
								{icon}
							</ListItemIcon>
						)}
						<ListItemText primary={children} />
						{isExternal && !subLinks ? (
							<ListItemIcon>
								<LaunchIcon sx={{ ml: 1, color: 'text.secondary' }} />
							</ListItemIcon>
						) : (
								subLinks?.length > 0 && subLinkIcon
							)
						}
					</Stack>
				</ListItemButton>
			</ListItem>
			{subLinks?.length > 0 && (
				// The extra <li> tags are only needed for A11y
				<li>
					<Collapse in={open} timeout="auto" unmountOnExit>
						<List component="div" disablePadding>
							{subLinks.map(link => (
								<ListItemButton
									key={link.href}
									sx={{ pl: 7 }}
									onClick={link.component ? () => navigate(link.href) : null}
									href={!link.component ? link.href : ''}
								>
									<ListItemText primary={link.label} />
								</ListItemButton>
							))}
						</List>
					</Collapse>
				</li>
			)}
		</>
	);
};

NavListItemButton.propTypes = {
	isExternal: bool,
	isReact: bool,
	href: string,
	ssoFeature: string,
	children: string,
	subLinks: arrayOf(SubLinkType),
	icon: element
};

// Use this to navigate to React pages
export const ReactPageNavListItemButton = ({
	href,
	children,
	subLinks,
	...props
}) => (
	<NavListItemButton isReact href={href} subLinks={subLinks} {...props}>
		{children}
	</NavListItemButton>
);

ReactPageNavListItemButton.propTypes = {
	href: string,
	children: string,
	subLinks: arrayOf(SubLinkType),
};

// Use this to navigate to Ember pages
export const EmberPageNavListItemButton = ({
	href,
	children,
	subLinks,
	...props
}) => (
	<NavListItemButton href={href} subLinks={subLinks} {...props}>
		{children}
	</NavListItemButton>
);

EmberPageNavListItemButton.propTypes = {
	href: string,
	children: string,
	subLinks: arrayOf(SubLinkType),
};

// Use this to navigate to external pages
export const ExternalPageNavListItemButton = ({
	href,
	ssoFeature,
	children,
	subLinks,
	...props
}) => (
	<NavListItemButton
		isExternal
		href={href}
		ssoFeature={ssoFeature}
		subLinks={subLinks}
		{...props}
	>
		{children}
	</NavListItemButton>
);

/*
 * For a normal external link, pass in the href and leave ssoFeature blank. For an external link
 * that uses single sign-on, pass in the ssoFeature name and leave the href blank.
 */
ExternalPageNavListItemButton.propTypes = {
	href: string,
	ssoFeature: string,
	children: string,
	subLinks: arrayOf(SubLinkType),
};

export const NavListItemButtonMapping = {
	ReactComponent: ReactPageNavListItemButton,
	EmberComponent: EmberPageNavListItemButton,
	ExternalComponent: ExternalPageNavListItemButton,
};
