/**
 * New Domains as per ASH Standards
 */
const apiEnvironment = {
	dev: 'dev',
	tst: 'tst',
	stg: 'stg',
	www: 'prod',
};

/**
 * This list is to support legacy domains until all services switch over to new domains
 */
const apiEnv = {
	dev: 'dev',
	tst: 'stg',
	stg: 'preprod',
	www: 'prod',
};

/**
 * This function return the env you are on based on the url
 */
const getApiEnvironment = (newDomain = true) => {
	const hostEnv = window.location.host?.split('.').shift();
	const currEnv = newDomain ? apiEnvironment[hostEnv] : apiEnv[hostEnv];

	return currEnv || 'dev'; // default env will be dev
};

export default getApiEnvironment;
