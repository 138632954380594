import Stack from '@mui/material/Stack';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import ASHLogo from '../../../assets/ASHLogo_desktop.svg';
import Link from '@mui/material/Link';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import Divider from '@mui/material/Divider';
import { visuallyHidden } from '@mui/utils';
import { FooterMenuType } from '../../../scripts/customPropTypes';
import {
	languageAssistLangs,
	externalLinkText,
	discriminationText,
	pdfPath,
	copyright,
} from '../../../scripts/footerData';
import { footerMenu } from '../../../scripts/routes';

const FooterMenu = ({ menus }) => {
	const { pathname } = useLocation();

	return (
		<Grid container direction="row" fontSize="14px" mt={8} spacing={3.5}>
			{menus &&
				menus.map(menu => (
					<Grid item xs={6} sm={6} lg={3} key={menu.href}>
						{menu.component ? (
							<Box
								sx={{
									a: {
										color: 'text.primary',
										textDecoration: 'none',
										fontSize: '1rem',
										fontFamily: 'Inter',
										'&:hover': {
											textDecoration: 'underline',
										},
									},
									display: menu.hide ? 'none' : 'block',
								}}
							>
								<RouterLink
									reloadDocument={pathname === menu.href}
									to={menu.href}
									target={menu.newTab ? '_blank' : '_self'}
									rel={menu.newTab ? 'noopener noreferrer' : ''}
								>
									{menu.label}
								</RouterLink>
							</Box>
						) : (
							<Link
								color="inherit"
								variant="body1"
								href={menu.href}
								target={menu.newTab ? '_blank' : '_self'}
								rel={menu.newTab ? 'noopener' : ''}
								underline="hover"
							>
								{menu.label}
							</Link>
						)}
					</Grid>
				))}
		</Grid>
	);
};

const Footer = () => (
	<Box
		component="footer"
		sx={{
			backgroundColor: 'background.default',
			color: 'text.primary',
			p: 0,
		}}
	>
		<Box>
			<Container>
				<Grid container mb={6}>
					<Grid item xs={12} sm={12} md={4} lg={3.5}>
						<Stack>
							<Typography mt={7} mb={1} variant="overline" component="p">
								A Product Of
							</Typography>
							<Box sx={{ width: '15rem', height: '2rem' }} mb={2}>
								<Link
									href="https://www.ashcompanies.com/"
									underline="none"
									target="_blank"
									rel="noopener"
								>
									<img
										src={ASHLogo}
										alt="American Specialty Health (Opens an external site in a new tab)"
									/>
								</Link>
							</Box>
						</Stack>
					</Grid>
					<Grid item display="flex" flexWrap="wrap" md={8}>
						<FooterMenu menus={footerMenu} />
					</Grid>
				</Grid>
				<Divider
					sx={{ borderColor: 'grey', margin: '2.5rem 0' }}
					orientation="horizontal"
				/>
				<Typography variant="body2" component="p" data-testid="away-links-text">
					<OpenInNewIcon
						sx={{ fontSize: 16, top: '0.1rem', position: 'relative' }}
					/>{' '}
					{externalLinkText}
				</Typography>
				<Typography
					variant="body2"
					component="p"
					my={3}
					data-testid="footer-not-discriminate-text"
				>
					{discriminationText}
				</Typography>
				{languageAssistLangs && (
					<Grid container direction="row" mb={6}>
						<Grid item>
							<Typography
								variant="body2"
								component="p"
								data-testid="footer-language-services-text"
							>
								For language services:
							</Typography>
						</Grid>
						<Grid item display="contents">
							{languageAssistLangs.map((lang, i, { length }) => (
								<Stack direction="row" key={lang.language}>
									<Typography variant="body2" component="p">
										<Link
											href={pdfPath}
											underline="hover"
											target="_blank"
											rel="noopener"
											color="secondary.main"
											sx={{ px: '4px' }}
										>
											{lang.language}
											<Box component="span" sx={visuallyHidden}>
												{lang.srText}
											</Box>
										</Link>
									</Typography>
									{length - 1 === i ? null : (
										<Divider
											orientation="vertical"
											flexItem
											sx={{ bgcolor: 'text.primary' }}
										/>
									)}
								</Stack>
							))}
						</Grid>
					</Grid>
				)}
			</Container>
		</Box>
		<Box sx={{ backgroundColor: 'primary.main' }} py={4} px={2}>
			<Container>
				<Stack
					spacing={2}
					direction={{ xs: 'column', sm: 'row' }}
					justifyContent="center"
				>
					<Typography
						variant="body2"
						color="primary.contrastText"
						textAlign={{ xs: 'center' }}
					>
						{copyright}
					</Typography>
				</Stack>
			</Container>
		</Box>
	</Box>
);

FooterMenu.propTypes = {
	menus: FooterMenuType,
};

export default Footer;
