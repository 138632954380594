import Faq from '@ashn/customerservice-react/FAQ';
import { product } from '../../scripts/miscConstants';
import PaddedContainer from '../shared/containers/PaddedContainer';
import PageSEO from '../shared/PageSEO';
import getApiEnvironment from '../../utils/environmentHelpers/getAPIEnvironments';

const FAQPage = () => (
	<>
		<PageSEO
			pageTitle="FAQ"
			description="We’re here to help! Contact us at Concierge@ashcare.com, call us at 800.514.2747 during operating hours, or use our online submission form."
		/>

		<PaddedContainer>
			<Faq apiEnv={getApiEnvironment()} product={product} hasOwnProvider />
		</PaddedContainer>
	</>
);

export default FAQPage;
