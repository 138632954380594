import { lazy } from 'react';
import FAQPage from '../components/faq/FAQ';
import { Programs } from './miscConstants';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import PersonAddIcon from '@mui/icons-material/PersonAdd';

const HomePage = lazy(() => import('../components/homePage/HomePage'));
const NotFoundErrorPage = lazy(() =>
	import('../components/shared/ErrorPages/NotFoundErrorPage')
);
const ServerErrorPage = lazy(() =>
	import('../components/shared/ErrorPages/ServerErrorPage')
);
const PrivacyPage = lazy(() => import('../components/privacy/PrivacyPage'));
const TermsPage = lazy(() => import('../components/terms/TermsPage'));
const ContactUsPage = lazy(() =>
	import('../components/contactUs/ContactUsPage')
);
const EditorialPolicyPage = lazy(() =>
	import('../components/editorialPolicy/EditorialPolicyPage')
);
const MemberRightsAndResponsibilitiesPage = lazy(() =>
	import(
		'../components/memberRightsAndResponsibilities/MemberRightsAndResponsibilitiesPage'
	)
);
const FallPreventionPage = lazy(() =>
	import('../components/fallPreventionPage/FallPreventionPage')
);
const VirtualFallPrevention = lazy(() =>
	import('../components/dashboard/VirtualFallPrevention')
);
const VirtualFitnessCoaching = lazy(() =>
	import('../components/dashboard/VirtualFitnessCoaching')
);
const VirtualWellBeingCoaching = lazy(() =>
	import('../components/dashboard/VirtualWellBeingCoaching')
);

const VirtualMSK = lazy(() =>
	import('../components/dashboard/VirtualMSK/VirtualMSK')
);
const VirtualPhysicalTherapyAndOccupationalTherapy = lazy(() =>
	import(
		'../components/dashboard/VirtualPhysicalTherapyAndOccupationalTherapy/VirtualPhysicalTherapyAndOccupationalTherapy'
	)
);

const AnthemPostLogin = lazy(() =>
	import(
		'../components/dashboard/VirtualFitnessAndRecovery/anthem/ProgramDetailPageAnthemPostLogin'
	)
);

// TODO: After 7/1:  Make changes for protect routes to use `ProtectedRoute` component. Currently its not required here since we have only home page in react here.

export const homePageRoutes = [
	{
		label: null,
		href: '/',
		isProtectedPage: true,
		program: Programs.VirtualFallPrevention,
		component: <VirtualFallPrevention />,
	},
	{
		label: null,
		href: '/',
		isProtectedPage: true,
		program: Programs.VirtualFitnessCoaching,
		component: <VirtualFitnessCoaching />,
	},
	{
		label: null,
		href: '/',
		isProtectedPage: true,
		program: Programs.VirtualWellBeingCoaching,
		component: <VirtualWellBeingCoaching />,
	},
	{
		label: null,
		href: '/',
		isProtectedPage: true,
		program: Programs.AIEnabledMSK,
		component: <VirtualMSK />,
	},
	{
		label: null,
		href: '/',
		isProtectedPage: true,
		program: Programs.VirtualPhysicalTherapyAndRehabilitation,
		component: <VirtualPhysicalTherapyAndOccupationalTherapy />,
	},
	{
		label: null,
		href: '/',
		isProtectedPage: false,
		component: <HomePage />,
	},
	{
		label: null,
		href: '/',
		isProtectedPage: false,
		component: <FallPreventionPage />,
	},
];

export const topNavBarRoutes = [
	{
		label: 'Login',
		href: '/identity/login',
		isProtectedPage: false,
		component: null,
		eventName: 'click_login',
	},
	{
		label: 'Register',
		href: '/identity/registration',
		isProtectedPage: false,
		component: null, // Ember pages and external links will have a null component.
		isCallToAction: true, // Call to action buttons will have different styling.
		eventName: 'click_registerCTA',
	},
];

export const secondNavBarRoutes = [
	{
		label: 'MSK',
		href: '/msk',
		isProtectedPage: true,
		program: Programs.AIEnabledMSK,
		component: <VirtualMSK />,
	},
	{
		label: 'Home',
		href: '/',
		isProtectedPage: true,
		program: Programs.VirtualFallPrevention,
		component: <VirtualFallPrevention />,
	},
	{
		label: 'Home',
		href: '/',
		isProtectedPage: true,
		program: Programs.VirtualFitnessCoaching,
		component: <VirtualFitnessCoaching />,
	},
	{
		label: 'Home',
		href: '/',
		isProtectedPage: true,
		program: Programs.VirtualWellBeingCoaching,
		component: <VirtualWellBeingCoaching />,
	},
	{
		label: 'Home',
		href: '/',
		isProtectedPage: true,
		program: Programs.VirtualFitnessAndRecovery,
		component: <AnthemPostLogin />,
	},
	{
		label: 'Live Sessions',
		href: '/live-sessions',
		isProtectedPage: true,
		program: Programs.VirtualPhysicalTherapyAndRehabilitation,
		component: <VirtualPhysicalTherapyAndOccupationalTherapy />,
	},
	{
		label: 'Learn',
		href: '/resourcelibrary/workouts/all',
		programFeature: 'DigitalWorkouts',
		isProtectedPage: true,
		component: null,
	},
	// navigation menu for well-being coaching program members
	{
		label: 'Learn',
		href: '/resourcelibrary',
		programFeature: 'ResourceLibrary',
		isProtectedPage: true,
		component: null,
	},
	{
		label: 'Buy Healthy',
		href: '/buy-healthy',
		programFeature: 'BuyHealthyMarketplace',
		isProtectedPage: true,
		component: null,
	},
	{
		label: 'Register',
		href: '/identity/registration',
		isProtectedPage: false,
		component: null,
		icon: <PersonAddIcon />,
	},
	{
		label: 'Login',
		href: '/identity/login',
		isProtectedPage: false,
		component: null,
		icon: <ExitToAppIcon />,
	},
	{
		label: 'Connected!',
		href: '',
		programFeature: 'ConnectedV2',
		isProtectedPage: true,
		component: null,
		subLinks: [
			{
				label: 'Dashboard',
				href: '/connected/ActivityTracker',
				component: null,
			},
			{
				label: 'Apps and Devices',
				href: '/connected/Devices',
				component: null,
			},
		],
	},
	{
		label: 'Store',
		ssoFeature: 'OTC', // Use ssoFeature instead of href to enable single sign-on
		programFeature: 'OTC',
		isProtectedPage: true,
		component: null,
		isExternal: true,
	},
];

// These are all Ember pages and only show when the user is logged in
export const profileRoutes = [
	{
		label: 'My Profile',
		href: '/myAccount/info',
		isReactPage: false,
		isProtectedPage: true,
	},
	{
		label: 'Password and Security',
		href: '/identity/profile/security',
		isReactPage: false,
		isProtectedPage: true,
	},
	{
		label: 'Payment History',
		href: '/payment/history',
		isProtectedPage: true,
		isReactPage: true,
		program: Programs.VirtualPhysicalTherapyAndRehabilitation,
	},
	{
		label: 'Logout',
		href: '/auth/logout',
		isReactPage: false,
		isProtectedPage: true,
	},
];

export const errorPages = [
	{
		label: null,
		href: '*',
		component: <NotFoundErrorPage />,
	},
	{
		label: null,
		href: '404',
		component: <NotFoundErrorPage />,
	},
	{
		label: null,
		href: '500',
		component: <ServerErrorPage />,
	},
];

export const footerMenu = [
	{
		label: 'Terms & Conditions',
		href: '/terms',
		newTab: false,
		component: <TermsPage />,
		hide: false,
	},
	{
		label: 'Privacy Statement',
		href: '/privacy',
		newTab: false,
		component: <PrivacyPage />,
		hide: false,
	},
	{
		label: 'Contact Us',
		href: '/contactus',
		newTab: false,
		component: <ContactUsPage />,
		hide: false,
	},
	{
		label: 'FAQ',
		href: '/faq',
		newTab: false,
		component: <FAQPage />,
		hide: true,
	},
	{
		label: 'Editorial Policy',
		href: '/editorialpolicy',
		newTab: false,
		component: <EditorialPolicyPage />,
		hide: false,
	},
	{
		label: 'Member Rights And Responsibilities',
		href: '/memberRightsAndResponsibilities',
		newTab: false,
		component: <MemberRightsAndResponsibilitiesPage />,
		hide: false,
	},
];

// TODO: Refactor this to use the routes above so we're not repeating the same info twice.
// private routes that users should NOT be able to get to when they are NOT logged in, will be redirected to login page
export const privateRoutes = [
	'/identity/profile',
	'/myAccount/info',
	'/resourcelibrary',
	'/connected',
];

// public routes that users should NOT be able to get to when they are logged in, will be redirected to dashboard page
export const publicRoutes = [
	'/identity/forgotPassword',
	'/identity/registration',
	'/identity/login',
	'/identity/forgotUsername',
];

// routes that users should be able to get to when they have nonskipable flow, will NOT be redirected to the flow page
export const nonFlowRoutes = ['/auth/logout'];
