import Container from '@mui/material/Container';
import { element, oneOfType, arrayOf, object } from 'prop-types';

const PaddedContainer = ({ sx, children }) => (
	<Container
		sx={{
			my: 8,
			...sx,
		}}
	>
		{children}
	</Container>
);

PaddedContainer.propTypes = {
	sx: object,
	children: oneOfType([element, arrayOf(element)]),
};

export default PaddedContainer;
